<template>
  <div>
    <p>{{ $t('onboarding.contactData.desc') }}</p>

    <v-form
      v-model="isFormValid"
      @submit.prevent="submit"
    >

      <v-row>

        <!-- STREET -->
        <v-col>
          <v-text-field
            id="street"
            type="text"
            :rules="rules.street"
            v-model="data.contactData.street"
            autocomplete="street"
            required
            :label="`${$t('onboarding.contactData.fields.street')} *`"
            outlined
            validate-on-blur
            ref="street"
            @input="checkValidation('street')"
          ></v-text-field>
        </v-col>

        <!-- HOUSE NR -->
        <v-col cols="12" sm="3">
          <v-text-field
            id="house-nr"
            type="text"
            :rules="rules.alphanumeric"
            v-model="data.contactData.houseNo"
            autocomplete="number"
            required
            :label="`${$t('onboarding.contactData.fields.houseNo')} *`"
            outlined
            validate-on-blur
            ref="house-nr"
            @input="checkValidation('house-nr')"
          ></v-text-field>
        </v-col>

      </v-row>

      <v-row>

        <!-- CITY -->
        <v-col>
          <v-text-field
            id="city"
            type="text"
            :rules="rules.city"
            v-model="data.contactData.city"
            autocomplete="city"
            required
            :label="`${$t('onboarding.contactData.fields.city')} *`"
            outlined
            validate-on-blur
            ref="city"
            @input="checkValidation('city')"
          ></v-text-field>
        </v-col>

        <!-- ZIP -->
        <v-col cols="12" sm="3">
          <v-text-field
            id="zip"
            type="tel"
            :rules="rules.zip"
            v-model="data.contactData.zip"
            required
            :label="`${$t('onboarding.contactData.fields.zip')} *`"
            outlined
            validate-on-blur
            ref="zip"
            @input="checkValidation('zip')"
          ></v-text-field>
        </v-col>

      </v-row>

      <v-row>

        <!-- COUNTRY OF RESIDENCE -->
        <v-col>
          <country-select
            :items="ewrCountries"
            v-model="data.contactData.countryResidence"
            :rules="rules.required"
            :label="`${$t('onboarding.contactData.fields.countryResidence')} *`"
            :default-country="defaultCountry"
          >
          </country-select>
        </v-col>

      </v-row>

      <v-row>

        <!-- MOBILE PHONE -->
        <v-col>
          <vue-tel-input-vuetify
            inputId="mobile"
            :rules="rules.hardRequired"
            autocomplete="mobilePhone"
            :value="data.contactData.mobile"
            required
            :label="`${$t('onboarding.contactData.fields.mobile')}`"
            outlined
            append-icon="mdi-menu-down"
            defaultCountry="DE"
            validate-on-blur
            ref="mobile"
            :error-messages="mobileErrorMsg"
            @input="changeMobileNumber"
            @blur="blurMobileInput"
          ></vue-tel-input-vuetify>
        </v-col>

      </v-row>
    </v-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import clone from '@/utils/clone';
import {
  alphanumeric, street, city, zip, required, hardRequired,
} from '@/utils/validators';
import CountrySelect from '@/components/Shared/InputFields/CountrySelect.vue';

export default {
  name: 'ContactData',
  components: {
    CountrySelect,
  },
  data() {
    return {
      data: clone(this.$store.state.user.data),
      rules: {
        alphanumeric,
        zip,
        street,
        city,
        required,
        hardRequired,
      },
      validMobile: false,
      mobileErrorMsg: null,
    };
  },
  watch: {
    'data.contactData.mobile': function (newVal) {
      if (newVal === '') {
        this.data.contactData.mobile = null;
      }
    },
  },
  computed: {
    ...mapFields('onboarding', [
      'isFormValid',
    ]),
    ...mapGetters('onboarding', [
      'editMode',
      'ewrCountries',
      'defaultCountry',
    ]),
  },
  methods: {
    ...mapActions('user', [
      'updateUser',
      'setSubStage',
      'updateOnboarding',
    ]),
    checkValidation(refName, children = false) {
      if (!this.isFormValid) {
        if (children) {
          this.$refs[refName].$children[1].validate();
        } else {
          this.$refs[refName].validate();
        }
      }
    },
    blurMobileInput() {
      if (!this.validMobile) {
        this.mobileErrorMsg = this.$t('validators.mobile.invalid');
      } else {
        this.mobileErrorMsg = null;
      }
    },
    changeMobileNumber(_, data) {
      this.validMobile = data.isValid;
      this.data.contactData.mobile = data.number.international;
      if (data.isValid) {
        this.mobileErrorMsg = null;
        this.checkValidation('mobile', true);
      }
    },
    async submit() {
      delete this.data.contactData.email;
      this.data.contactData.mailbox = 'testMailbox';
      this.data.contactData.phone = '+4912345678';
      this.data.contactData.zip.trim();
      const input = {
        investor: {
          personal: {
            contactData: this.data.contactData,
          },
        },
        mainStage: 'personal',
        subStage: 'contactData',
      };
      try {
        await this.updateOnboarding({
          input,
          newData: this.data,
        });
        if (this.editMode) {
          this.$store.dispatch('onboarding/editMode', null);
          this.$router.push({
            name: 'Onboarding.Personal',
            params: {
              step: 'Overview',
            },
          });
        } else {
          this.setSubStage('identificationdata');
        }
      } catch (error) {
        this.$notify.error(error.message);
      }
    },
  },
};
</script>
