<template>
  <v-select
    ref='vSelect'
    v-bind="$attrs"
    item-text="name"
    item-value="alpha3"
    @input="$emit('input', $event)"
    outlined
  >
  <!-- TODO: perhaps can be refactored to avoid duplication -->
    <template v-slot:prepend-item>
      <v-list-item @click="selectGermany($attrs['default-country'].alpha3)">
        <div class="d-flex align-center">
          <h2>
            {{ flag($attrs['default-country'].alpha2) }}
          </h2>
          <span class="ml-3">
            {{$attrs['default-country'].name}}
          </span>
        </div>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
    <template v-slot:selection="{ item: country }" >
      <div class="d-flex align-center">
        <h2>
          {{ flag(country.alpha2) }}
        </h2>
        <span class="ml-3">
          {{country.name}}
        </span>
      </div>
    </template>
    <template v-slot:item="{ item: country }" >
      <div class="d-flex align-center">
        <h2>
          {{ flag(country.alpha2) }}
        </h2>
        <span class="ml-3">
          {{country.name}}
        </span>
      </div>
    </template>
  </v-select>
</template>

<script>
import { countryCodeEmoji } from 'country-code-emoji';

export default {
  name: 'CountrySelect',
  methods: {
    flag(countryCode) {
      return countryCodeEmoji(countryCode);
    },
    selectGermany(event) {
      this.$emit('input', event);
      this.$refs.vSelect.blur();
    },
  },
};
</script>
